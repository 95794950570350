import fetchFeatureFlags from '../../utilities/fetchFeatureFlags';
import { FeatureFlag, FeatureFlags } from '../../utilities/isFeatureFlagEnabled';
import { FeatureFlagValue } from './ControlPanel.typings';

/** Initialize feature flags to session storage */
export const initializeSessionFlags = (flags: FeatureFlags) => {
  for (const [key, value] of Object.entries(flags)) {
    if (sessionStorage.getItem(key) === null) {
      sessionStorage.setItem(key, value);
    }
  }
};

export const getFeatureFlags = async (): Promise<FeatureFlags> => {
  await fetchFeatureFlags();
  initializeSessionFlags(window.__config__);
  let flags: Partial<FeatureFlags> = {};

  Object.entries(window.__config__).forEach(([key, value]: [FeatureFlag, FeatureFlagValue]) => {
    // only get the boolean feature flags
    if (value === 'true' || value === 'false') {
      if (sessionStorage.getItem(key) === null) {
        flags = { ...flags, [key]: value };
      } else {
        flags = { ...flags, [key]: sessionStorage.getItem(key) };
      }
    }
  });
  return flags as FeatureFlags;
};
