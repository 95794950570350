import React, { ReactElement } from 'react';
import { withStyles } from '@material-ui/core';
import Button from 'bank-component-library/ui/atoms/Button';
import Error from '../error/error';
import IgniteModal, { IgniteModalActions, IgniteModalContent } from './igniteModal';
import type { FinalFormSubmit, StyleProps } from '../../utilities/types';
import styles from './modal.styles';

export type FormModalProps = {
  visible: boolean;
  titleText: string;
  contentText?: ReactElement | string;
  confirmText: string;
  cancelText?: string;
  disableConfirm?: boolean;
  children?: React.ReactNode | null;
  onLoading?: boolean;
  handleSubmit: FinalFormSubmit | (() => void);
  onCancel?: () => void;
  onTryAgain?: () => void;
  responseError?: boolean;
  onEscapeKeyDown?: () => void;
  isWide?: boolean;
  cancelDataTrackTitle?: string;
  cancelDataTrackPage?: string;
  dataTrackTitle?: string;
  dataTrackPage?: string;
};

const FormModal = ({
  visible,
  titleText,
  classes,
  contentText,
  confirmText,
  cancelText,
  disableConfirm,
  children,
  handleSubmit,
  onLoading,
  onTryAgain,
  onCancel,
  responseError,
  onEscapeKeyDown,
  isWide,
  cancelDataTrackTitle,
  cancelDataTrackPage,
  dataTrackTitle,
  dataTrackPage,
}: FormModalProps & StyleProps) => (
  <IgniteModal
    visible={visible}
    data-test="confirmation-modal"
    title={titleText}
    classes={classes}
    onEscapeKeyDown={onEscapeKeyDown}
    isWide={isWide}
  >
    {responseError ? (
      <div>
        <Error onClick={onTryAgain} btn-data-test="reload-button" />
      </div>
    ) : (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <IgniteModalContent variant="text">{contentText}</IgniteModalContent>
        <IgniteModalContent> {children}</IgniteModalContent>
        <IgniteModalActions isCL>
          {onCancel && (
            <Button
              variant="outlined"
              data-test="confirmation-modal-cancel-button"
              disabled={onLoading}
              data-track-title={cancelDataTrackTitle}
              data-track-page={cancelDataTrackPage}
              onClick={onCancel}
            >
              {cancelText || 'Cancel'}
            </Button>
          )}
          <Button
            data-test="confirmation-modal-primary-button"
            isLoading={onLoading}
            type="submit"
            data-track-title={dataTrackTitle}
            data-track-page={dataTrackPage}
          >
            {confirmText}
          </Button>
        </IgniteModalActions>
      </form>
    )}
  </IgniteModal>
);
export default withStyles(styles)(FormModal);
