import { AppBar, withWidth } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { push } from 'connected-react-router';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction, compose } from 'redux';
import debounce from 'lodash/debounce';
import { ThunkDispatch } from 'redux-thunk';
import styles from './header.styles';
import HeaderToolbar from './headerToolbar';
import HeaderCollapse from './headerCollapse';
import type { ReduxState } from '../../reducers/index';
import {
  ACCESS_TOKEN_STORAGE_KEY,
  getAccessToken,
  getTokenGivenName,
} from '../../utilities/authentication';
import type { StyleProps, WidthProps, Account } from '../../utilities/types';
import { fetchAccounts } from '../../containers/accountDashboard/accountDashboard.actions';
import { SkipLink } from '../links/skipLink';
import Routes from '../../containers/routes/routes.constants';
import type { AccountsServiceParams } from '../../containers/accountDashboard/accountDashboard.service';
import { getMessageCountsService } from '../../containers/messages/messages.service';
import HeaderNavbar from './headerNavbar';
import shouldFdicLogoRender from '../../utilities/shouldFdicLogoRender';

type StateProps = {
  pathname: string;
  accounts: Account[];
  accountIsLoading: boolean;
  nextPageAvailable: boolean;
};

type DispatchProps = {
  fetchAccounts: (params: AccountsServiceParams) => void;
  onSignOff: any;
};

type Props = {
  isDark?: boolean;
  title?: string;
  isDashboardCaching?: boolean;
};

type HeaderProps = Props & StyleProps & WidthProps & StateProps & DispatchProps;

type State = {
  name: string | null | undefined;
  headerScrollBreak: number;
  active: boolean;
  headerHoverable: boolean;
  isScrolledDown: boolean;
  transitionState: 'closed' | 'opening' | 'closing' | 'opened';
  isHamburgerVisible: boolean;
  usingKeyboard: boolean;
  unreadMessages: number;
};

const collapseRef = React.createRef<HTMLDivElement>();
const itemsRef = React.createRef<HTMLDivElement>();
const hamburgerRef = React.createRef<HTMLButtonElement>();

export class Header extends React.Component<HeaderProps, State> {
  headerRef: React.RefObject<null>;

  firstLinkRef: React.RefObject<HTMLAnchorElement>;

  constructor(props: HeaderProps) {
    super(props);
    this.state = {
      name: getTokenGivenName(getAccessToken(ACCESS_TOKEN_STORAGE_KEY)),
      active: false,
      headerHoverable: true,
      isScrolledDown: false,
      transitionState: 'closed',
      headerScrollBreak: 60,
      isHamburgerVisible: false,
      usingKeyboard: false,
      unreadMessages: 0,
    };
    this.scrollHandler = debounce(this.scrollHandler, 25);
    this.updateHeaderHeight = this.updateHeaderHeight.bind(this);
    this.headerRef = React.createRef();
    this.firstLinkRef = React.createRef();
  }

  componentDidMount() {
    this.fetchMessageCounts();
    document.addEventListener('keydown', this.escKeyHandler);
    document.addEventListener('mousemove', this.hoverHandler);
    window.addEventListener('scroll', this.scrollHandler);
    this.scrollHandler();

    this.updateHeaderHeight();

    // Refs are not available on the first render, need to wait until the component is mounted before
    // deciding if the hamburger is being shown or not. The clientHeight is 0 if an element has display: none.
    /* eslint-disable react/no-did-mount-set-state */
    if (
      hamburgerRef.current &&
      hamburgerRef.current.clientHeight > 0 &&
      !this.state.isHamburgerVisible
    ) {
      this.setState({ isHamburgerVisible: true });
    } else if (this.state.isHamburgerVisible) {
      this.setState({ isHamburgerVisible: false });
    }
    /* eslint-enable react/no-did-mount-set-state */
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.isScrolledDown !== this.state.isScrolledDown) this.updateHeaderHeight();
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (!hamburgerRef.current) return state;
    if (hamburgerRef.current.clientHeight > 0 && !state.isHamburgerVisible) {
      return { ...state, isHamburgerVisible: true };
    }
    if (hamburgerRef.current.clientHeight === 0 && state.isHamburgerVisible) {
      return { ...state, isHamburgerVisible: false };
    }
    return state;
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escKeyHandler);
    window.removeEventListener('scroll', this.scrollHandler);
    document.removeEventListener('mousemove', this.hoverHandler);
  }

  updateHeaderHeight = () => {
    if (this.headerRef && this.headerRef.current) {
      const { clientHeight } = this.headerRef.current;
      if (clientHeight === 200 || clientHeight === 160)
        this.setState({ headerScrollBreak: clientHeight - 100 });
    }
  };

  escKeyHandler = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.setState({ active: false, headerHoverable: false });
    }
  };

  hoverHandler = (event: MouseEvent) => {
    if (!this.state.headerHoverable && event) {
      this.setState({ headerHoverable: true });
    }
  };

  scrollHandler = () => {
    const { isScrolledDown, headerScrollBreak } = this.state;

    if (!isScrolledDown && window.scrollY > headerScrollBreak && !shouldFdicLogoRender())
      this.setState({ isScrolledDown: true });
    if (isScrolledDown && window.scrollY <= headerScrollBreak)
      this.setState({ isScrolledDown: false });
  };

  fetchMessageCounts = async () => {
    let response;
    try {
      response = await getMessageCountsService();
    } catch {
      // Ignore error
      return;
    }
    if (response && response.unread) {
      this.setState({ unreadMessages: parseInt(response.unread, 10) });
    }
  };

  render() {
    const {
      isDark,
      width,
      classes = {},
      title,
      pathname = '',
      accounts,
      accountIsLoading,
      isDashboardCaching,
      nextPageAvailable,
      onSignOff,
    } = this.props;
    const {
      active = false,
      headerHoverable = true,
      transitionState,
      name,
      isScrolledDown,
      isHamburgerVisible,
      usingKeyboard,
      unreadMessages,
    } = this.state;
    const isBiggerThanMedium = (w) => w === 'lg' || w === 'xl';
    const isFdicLogoVisible = shouldFdicLogoRender();
    const headerWithBackgroundClass = isDark
      ? classes.headerWithBackground
      : classes.headerWithoutBackground;

    const mouseHandler = (open: boolean) => () => {
      if (this.state.active !== open && isBiggerThanMedium(width)) {
        this.setState({ active: open, usingKeyboard: false }, () => {
          collapseRef?.current?.focus();
        });
      }
    };

    const onMouseEnter = () => {
      if (isBiggerThanMedium(width)) {
        this.setState({ active: false });
      }
    };

    const menuToggle = () => {
      this.setState({ active: !active });
      this.firstLinkRef.current.focus();
    };

    return (
      <AppBar
        id="header"
        className={classNames(classes.appBar, {
          [classes.headerCollapsedWithLogo]: isScrolledDown && isFdicLogoVisible,
          [classes.headerCollapsed]: isScrolledDown && !isFdicLogoVisible,
          [headerWithBackgroundClass]: !isFdicLogoVisible,
          [classes.headerWithBackgroundAndLogo]: isFdicLogoVisible,
          [classes.headerClose]: active,
          [classes.disableHover]: !headerHoverable,
        })}
        ref={this.headerRef}
      >
        <SkipLink href="#main" className={classes.skipLink}>
          Skip to main content
        </SkipLink>

        <HeaderToolbar
          isScrolledDown={isScrolledDown}
          active={active}
          title={title}
          isDark={isDark}
          onMouseEnter={onMouseEnter}
          transitionState={transitionState}
          isMenuExpanded={this.state.active}
          menuToggle={menuToggle}
          hamburgerRef={hamburgerRef}
          isDashboardCaching={isDashboardCaching}
        />

        <HeaderCollapse
          isDark={isDark}
          active={active}
          onEnter={() => this.setState({ transitionState: 'opening' })}
          onEntered={() => this.setState({ transitionState: 'opened' })}
          onExit={() => this.setState({ transitionState: 'closing' })}
          onExited={() => this.setState({ transitionState: 'closed' })}
          onFocus={() => this.setState({ active: true, usingKeyboard: true })}
          onBlur={() => this.setState({ active: false, usingKeyboard: false })}
          onMouseLeave={mouseHandler(false)}
          collapseRef={collapseRef}
          onMouseEnterTrue={mouseHandler(true)}
          onMouseEnterFalse={mouseHandler(false)}
          usingKeyboard={usingKeyboard}
          transitionState={transitionState}
          itemsRef={itemsRef}
          title={title}
          isScrolledDown={isScrolledDown}
        >
          <HeaderNavbar
            name={name}
            transitionState={transitionState}
            isDashboardCaching={isDashboardCaching}
            itemsRef={itemsRef}
            isHamburgerVisible={isHamburgerVisible}
            isDark={isDark}
            active={active}
            pathname={pathname}
            accounts={accounts}
            nextPageAvailable={nextPageAvailable}
            accountIsLoading={accountIsLoading}
            unreadMessages={unreadMessages}
            onSignOff={onSignOff}
            ref={this.firstLinkRef}
          />
        </HeaderCollapse>
      </AppBar>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  pathname: state.router ? state.router.location.pathname : '',
  accounts: state.accounts.data,
  nextPageAvailable: state.accounts.nextPageAvailable,
  accountIsLoading: state.accounts.isLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<null, null, AnyAction>) => ({
  fetchAccounts: () => dispatch(fetchAccounts({ purpose: 'dashboard' })),
  onSignOff: () => {
    dispatch(push(Routes.LOGOUT));
  },
});

export default compose(
  withStyles(styles, { name: 'Header' }),
  withWidth(),
  connect(mapStateToProps, mapDispatchToProps)
)(Header);
