import type { AllAccount } from '../../domain/Account/AllAccount';
import currencyFormatter, { normalizeDollarStringToNumber } from '../../formatters/currency';
import type { ExternalAccountType } from '../addExternalAccount/addExternalAccount.actions';
import { ExternalAccountTypes } from '../addExternalAccount/addExternalAccount.actions';
import i18n from '../../strings/i18n';

const isDigitRegex = /^\d+$/;
const isDecimalRegex = /^\d*\.?\d{0,2}$/;
export const isDigit = (fieldLabel: string) => (value: string | null) =>
  isDigitRegex.test(value) ? undefined : `${fieldLabel} must be only digits`;

export const isDecimal = (value: string | null) =>
  isDecimalRegex.test(value) ? undefined : 'Must be a decimal';

export const hasLengthOfTwo = (value?: string | null) =>
  value && value.length === 2 ? undefined : 'Please enter 2 digits';
export const hasLengthOfNine = (value: string | null) =>
  value.length === 9 ? undefined : 'Bank Routing Number must be 9 digits';
export const isBetweenThreeAndSeventeenChars = (value: string | null) =>
  value.length >= 3 && value.length <= 17
    ? undefined
    : 'Account Number must be between 3 and 17 digits';
export const isValidAccountType = (accountType?: ExternalAccountType | null) =>
  accountType === ExternalAccountTypes.CHECKING || accountType === ExternalAccountTypes.SAVINGS
    ? undefined
    : 'Must be valid account type';
export const isChecked = (check?: boolean | null) =>
  check ? undefined : 'Please acknowledge the Terms and Conditions.';

export const isACHChecked = (check?: boolean | null) => (check ? undefined : 'You Must Agree.');

export const minFundingAmountForNewAccount =
  (account: AllAccount, availableBalance: string, min: number) => (value: string | null) => {
    if (!value) {
      return `${i18n({ FORM_INSTRUCTION: 'REQUIRED_AMOUNT_TEXT' })}`;
    }

    const { productType = '' } = account;
    if (
      parseFloat(normalizeDollarStringToNumber(value)) >
      parseFloat(normalizeDollarStringToNumber(availableBalance))
    ) {
      return 'Amount is more than your available balance';
    }
    return isDecimalRegex.test(value) && parseFloat(value) >= min
      ? undefined
      : `A minimum deposit of ${currencyFormatter(
          min,
          !Number.isInteger(min)
        )} is required to fund a ${productType} account`;
  };

export default {
  isDigit,
  hasLengthOfNine,
  isBetweenThreeAndSeventeenChars,
  isValidAccountType,
  isChecked,
  minFundingAmountForNewAccount,
};
