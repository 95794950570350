export default Object.freeze({
  ADA_DISCLOSURE_DESC_BY_ID: 'disclosure-text',
  ADA_STREET_ADDRESS: 'instructionStreetAddress',
  ADA_DOB: 'instructionDOB',
  ADA_SSN: 'instructionSSN',
  ADA_SECURITY_QUESTION: 'instructionSecurityQuestion',
  ADA_CITIZEN: 'instructionCitizen',
  ADA_TRANSACTION_AMOUNT: 'transactionAmount',
  ADA_ACCOUNT_HIDE_REVEAL: 'accountHideReveal',
  ADA_EDIT_FUNDS: 'editFunds',
  ADA_EDIT_RENEWAL: 'editRenewal',
});
