import React, { useState } from 'react';
import ImagesFileNames from '../../images';
import useStyles from './fdicLogo.style';
import i18n from '../../strings/i18n';
import { StandardText } from '../typography/typography';
import SVGImage from '../svgImage';
import colors from '../colors/colors';

export type FDICProps = {
  isDark?: boolean;
};

const FDICLogo = (props: FDICProps) => {
  const { isDark } = props;
  const [showFDICContent, setShowFDICContent] = useState(false);
  const classes = useStyles();

  const handleContentLoading = () => {
    setShowFDICContent(true);
  };

  return (
    <div className={classes.fdicLogoContainer} data-test="fdic-logo-container">
      <div className={classes.fdicSvgLogoWrapper}>
        <SVGImage
          imageName={ImagesFileNames.iconFDICLogoSvg}
          handleAfterInjection={handleContentLoading}
          role="img"
          ariaHidden="false"
          fill={isDark ? colors.white : colors.darkNavyBlue}
          ariaLabel={i18n({ fdicLogoContent: 'imageLabel' })}
        />
      </div>
      {showFDICContent && (
        <StandardText className={classes.fdicLogoContent} data-test="fdic-logo-content">
          {i18n({ fdicLogoContent: 'content' })}
        </StandardText>
      )}
    </div>
  );
};

export default FDICLogo;
