import React, { Ref, forwardRef, useEffect, useState } from 'react';
import type { FieldRenderProps } from 'react-final-form';
import {
  InputLabel,
  FormHelperText,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TableCell,
  TableRow,
  MenuItem,
  InputAdornment,
} from '@material-ui/core';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { kebabCase } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import classnames from 'classnames';
import moment from 'moment';
import { Checkbox, Icon } from 'bank-component-library/ui/atoms';
import Select from '../inputs/select';
import ResizableSelect from '../inputs/resizableSelect';
import datePickerStyles from './datePicker.styles';
import type { StyleProps } from '../../utilities/types';
import {
  TextField,
  trialDepositsInputComponent,
  CurrencyField,
  SSNField,
  BirthDateField,
  EmailField,
  ZipcodeField,
  PhoneNumberField,
  PasswordField,
  otpInputComponent,
  WholeCurrencyField,
} from '../inputs/inputs';
import type { CheckProps } from '../inputs/inputs';
import ErrorWrapper from '../../containers/newAccountOpening/errorWrapper/errorWrapper';
import {
  ScreenReaderText,
  InlineLinkText,
  BodyTextMedium,
  StandardText,
} from '../typography/typography';
import { normalizeDollarStringToNumber } from '../../formatters/currency';
import { SelectField } from '../cms/inputText.constants';
import Routes from '../../containers/routes/routes.constants';
import { clickTrack } from '../../analytics/clickTracking.constants';
import {
  useNoPhoneCheckStyles,
  useRadioStyles,
  useCheckboxLabelStyles,
  useCheckboxStyles,
  Checkbox as MUIFinalFormCheckbox,
  currencyFieldStyles,
  checkBoxFormError,
} from './finalFormInputs.styles';
import i18n from '../../strings/i18n';
import ImagesFileNames from '../../images';
import SVGImage from '../svgImage';
import { useHelperTextStyles } from '../securityTile/securityTile.styles';
import { CheckboxCheckedIconSVG } from '../svg/svg';
import { CIRCLE_EXCLAMATION } from '../icons/icons.constants';

type TextFieldProps = {
  ariaRequired?: boolean;
  ['data-test']?: string;
  helperText: string;
  label: string;
  labelId: string;
  maxLength?: number;
  multiline?: boolean;
  placeholder: string;
  shrinkLabel?: boolean;
  id?: string;
  showErrorClass?: ClassNameMap;
};

type TextFieldRenderProps = TextFieldProps & FieldRenderProps<'textfield'>;

export const RenderTextField: React.FC<TextFieldRenderProps> = ({
  input,
  label,
  labelId,
  placeholder,
  helperText = '',
  shrinkLabel,
  'data-test': dataTest,
  ariaRequired,
  id,
  meta: { active, dirtySinceLastSubmit, error, submitError, touched },
  ...rest
}: TextFieldRenderProps) => {
  let formHelperTextProps;
  const hasSubmitError = !dirtySinceLastSubmit && !!submitError;
  const hasError = (touched && !active && !!error) || hasSubmitError;
  const helperTextError = hasSubmitError ? submitError : error;

  if (hasError) {
    formHelperTextProps = { error: true, component: ErrorWrapper };
  }

  return (
    <TextField
      {...input}
      {...rest}
      id={id}
      labelId={labelId}
      label={label}
      data-test={dataTest}
      placeholderText={placeholder || label}
      helperText={hasError ? helperTextError : helperText}
      error={hasError}
      aria-invalid={touched && !!helperTextError}
      ariaRequired={ariaRequired}
      FormHelperTextProps={formHelperTextProps}
      shrink={shrinkLabel}
    />
  );
};

export const RenderEmailField: React.ComponentType<TextFieldRenderProps> = ({
  input,
  label,
  labelId,
  meta: { touched, active, error, submitError, dirtySinceLastSubmit },
  ...rest
}: TextFieldRenderProps) => {
  const displayError = !dirtySinceLastSubmit && submitError ? submitError : error;
  return (
    <EmailField
      {...input}
      {...rest}
      placeholderText={label}
      label={label}
      labelId={labelId}
      error={touched && !active && !!displayError}
      helperText={touched && !active && displayError ? displayError : ''}
      aria-invalid={touched && !active && !!displayError}
      ariaRequired
      FormHelperTextProps={{ error: true, component: ErrorWrapper }}
    />
  );
};

export const RenderTelephoneField: React.ComponentType<any> = ({
  input,
  label,
  labelId,
  meta: { touched, active, error, dirtySinceLastSubmit, submitError },
  ...rest
}: TextFieldRenderProps) => {
  const displayError = !dirtySinceLastSubmit && submitError ? submitError : error;
  return (
    <PhoneNumberField
      {...input}
      {...rest}
      placeholderText={label}
      label={label}
      labelId={labelId}
      error={touched && !active && !!displayError}
      helperText={touched && !active && displayError ? displayError : ''}
      aria-invalid={touched && !!displayError}
      FormHelperTextProps={{ error: true, component: ErrorWrapper }}
    />
  );
};

type PhoneFieldProps = {
  label: string;
  ariaRequired: boolean;
  ariaDescribed: string;
  ['data-test']: string;
};

export const RenderPhoneTypeField = ({
  input,
  label,
  ariaRequired,
  ariaDescribed,
  'data-test': dataTest,
  meta: { touched, error, dirtySinceLastSubmit, submitError },
  ...rest
}: FieldRenderProps<'telephone'> & PhoneFieldProps): React.ReactElement<
  React.ComponentProps<typeof Select>
> => {
  const displayError = !dirtySinceLastSubmit && submitError ? submitError : error;
  return (
    <Select
      {...input}
      {...rest}
      id="phoneType-select"
      label={label}
      error={touched && !!displayError}
      helperText={touched && displayError ? displayError : ''}
      aria-invalid={touched && !!displayError}
      inputProps={{
        'data-test': dataTest,
        'aria-required': ariaRequired,
        'aria-label': label,
        'aria-describedby': ariaDescribed,
      }}
    >
      {[
        <option key="mobile" value="Mobile">
          Mobile
        </option>,
        <option key="other" value="Other">
          Other
        </option>,
      ]}
    </Select>
  );
};

export const RenderZipcodeField: React.ComponentType<TextFieldRenderProps> = ({
  input,
  label,
  labelId,
  meta: { touched, active, error, dirtySinceLastSubmit, submitError },
  ...rest
}: TextFieldRenderProps) => {
  const displayError = !dirtySinceLastSubmit && submitError ? submitError : error;
  return (
    <ZipcodeField
      {...input}
      {...rest}
      placeholderText={label}
      label={label}
      labelId={labelId}
      error={touched && !active && !!displayError}
      helperText={touched && !active && displayError ? displayError : ''}
      aria-invalid={touched && !!displayError}
      FormHelperTextProps={{ error: true, component: ErrorWrapper }}
    />
  );
};

export const renderTrialDepositsField = ({
  input,
  meta: { touched, active, error },
  ...rest
}: TextFieldRenderProps) => {
  return (
    <TextField
      {...input}
      {...rest}
      InputProps={{ inputComponent: trialDepositsInputComponent }}
      error={touched && !active && !!error}
      maxLength={2}
      placeholderText="00"
      required
    />
  );
};

export const renderOtpField = ({
  input,
  meta: { submitError, dirtySinceLastSubmit },
  ...rest
}: TextFieldRenderProps) => {
  const showError = submitError && !dirtySinceLastSubmit;
  return (
    <TextField
      {...input}
      {...rest}
      error={showError}
      InputProps={{ inputComponent: otpInputComponent }}
      maxLength={4}
      required
    />
  );
};

type CurrencyFieldRenderProps = TextFieldProps &
  FieldRenderProps<'currency'> & {
    errorLabelClassName?: string;
  };

export const renderCurrencyField = ({
  input,
  label,
  placeholder,
  helperText = '',
  meta: { touched, error, active },
  errorLabelClassName,
  ...rest
}: CurrencyFieldRenderProps) => {
  const errorMessage = touched && !active && error;

  const formHelperTextProps = { error: !!errorMessage, component: ErrorWrapper } as const;

  return (
    <CurrencyField
      {...input}
      {...rest}
      // TODO: Will remove this when will support red color label if error as a prop in all other component.
      label={<span className={errorMessage ? errorLabelClassName : ''}>{label}</span>}
      placeholderText={placeholder || label}
      helperText={errorMessage || helperText}
      error={!!errorMessage}
      aria-invalid={!!errorMessage}
      onBlur={() => {
        const amount = normalizeDollarStringToNumber(input.value);
        input.onChange(amount ? parseFloat(amount).toFixed(2) : '0');
        input.onBlur();
      }}
      FormHelperTextProps={formHelperTextProps}
      ariaRequired
    />
  );
};

export const renderWholeCurrencyField = ({
  input,
  label,
  placeholder,
  helperText = '',
  meta: { touched, error, active },
  ...rest
}: TextFieldRenderProps) => {
  const classes = currencyFieldStyles();
  const errorMessage = touched && !active && error;

  const formHelperTextProps = { error: !!errorMessage, component: ErrorWrapper } as const;

  return (
    <WholeCurrencyField
      {...input}
      {...rest}
      label={label}
      placeholderText={placeholder || label}
      helperText={errorMessage || helperText}
      error={!!errorMessage}
      aria-invalid={!!errorMessage}
      FormHelperTextProps={formHelperTextProps}
      ariaRequired
      InputAdornmentProps={
        error &&
        !!errorMessage && (
          <InputAdornment position="end" className={classes.errorIconFocusStyle}>
            <SVGImage imageName={ImagesFileNames.iconErrorSvg} ariaHidden="true" />
          </InputAdornment>
        )
      }
    />
  );
};

const preventDefaultOnEvent = (e) => e.preventDefault();

export const RenderPasswordField: React.ComponentType<TextFieldRenderProps> = ({
  input,
  label,
  ariaRequired,
  placeholder,
  helperText = '',
  showErrorClass,
  meta: { touched, active, error },
  dataTest,
  ...rest
}: TextFieldRenderProps) => {
  const classes = currencyFieldStyles();
  const helperTextClasses = useHelperTextStyles();

  const [isBlurError, setIsBlurError] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState<any>(helperText);

  useEffect(() => {
    if (active) {
      setIsBlurError(false);
    }
  }, [active]);

  return (
    <PasswordField
      {...input}
      {...rest}
      label={label}
      inputProps={{ className: showErrorClass, 'data-test': dataTest }}
      onCopy={preventDefaultOnEvent}
      onCut={preventDefaultOnEvent}
      onPaste={preventDefaultOnEvent}
      onBlur={(e) => {
        if (e.target && e.target.value === '') {
          setIsBlurError(true);
          setPasswordHelperText(() => (
            <InputAdornment position="start" className={classes.passwordEmptyErrorStyle}>
              <SVGImage
                imageName={ImagesFileNames.iconErrorSvg}
                className={helperTextClasses.greenCheckmark}
                dataTest="error-image"
                ariaHidden="true"
              />
              <p>The field is required.</p>
            </InputAdornment>
          ));
        }
        input.onBlur(e);
      }}
      onFocus={() => {
        setIsBlurError(false);
        setPasswordHelperText(helperText);
      }}
      onContextMenu={preventDefaultOnEvent}
      helperText={touched && !active && error ? error : passwordHelperText}
      error={(touched && !active && !!error) || isBlurError}
      aria-invalid={touched && (!!isBlurError || !!error)}
      FormHelperTextProps={{
        isError: touched && !active && (!!error || !!isBlurError),
        component: ErrorWrapper,
      }}
      ariaRequired
    />
  );
};

export const RenderSSNField: React.ComponentType<TextFieldRenderProps> = ({
  input,
  label,
  ariaRequired,
  placeholder,
  helperText = '',
  meta: { touched, active, error },
  ...rest
}: TextFieldRenderProps) => (
  <SSNField
    {...input}
    {...rest}
    label={label}
    onCopy={preventDefaultOnEvent}
    onCut={preventDefaultOnEvent}
    onPaste={preventDefaultOnEvent}
    onContextMenu={preventDefaultOnEvent}
    helperText={touched && !active && error ? error : helperText}
    error={touched && !active && !!error}
    aria-invalid={touched && !!error}
    FormHelperTextProps={{ error: touched && !active && !!error, component: ErrorWrapper }}
    ariaRequired
  />
);

export type DatePickerProps = {
  ['data-test']?: string;
  disabled?: boolean;
  label: string;
  startDate?: moment.Moment;
  helperText?: string;
  maxDate?: moment.Moment;
  minDate?: moment.Moment;
  onClose?: () => void;
  inlineErrorId?: string;
};

export const RenderBirthDateField: React.ComponentType<
  DatePickerProps & FieldRenderProps<'birthdate'>
> = ({
  input,
  meta: { touched, active, error },
  helperText = '',
  ...rest
}: DatePickerProps & FieldRenderProps<'birthdate'>) => (
  <BirthDateField
    {...input}
    {...rest}
    error={touched && !active && !!error}
    helperText={touched && !active && error ? error : helperText}
    aria-invalid={touched && !!error}
    FormHelperTextProps={{ error: touched && !active && !!error, component: ErrorWrapper }}
    ariaRequired
  />
);

export const renderDatePicker = withStyles(datePickerStyles)(
  (props: FieldRenderProps<'datepicker'> & DatePickerProps & StyleProps) => {
    const {
      input,
      id,
      classes,
      meta,
      label,
      helperText,
      startDate,
      maxDate: maxDateProp, // defaults to one year from today
      minDate,
      disabled,
      'data-test': dataTest,
      onClose,
      inlineErrorId,
    } = props;

    const [open, setOpen] = useState(false);

    const isError = !!meta.error;

    /**
     * Without the check on meta.dirty, if a user removes the input values,
     * input.value will be falsy and the value will reset to startDate
     */
    const dateValue = input.value || meta.dirty ? input.value : startDate;

    const maxDate = maxDateProp ?? moment().add(1, 'year');

    return (
      <div
        data-test={dataTest}
        className={classnames(classes.datePickerContainer, isError ? 'date-error' : '')}
      >
        <ScreenReaderText>
          Click the down arrow key to interact with the calendar and select a date.
        </ScreenReaderText>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={dateValue}
            open={open}
            onOpen={() => setOpen(true)}
            onChange={input.onChange}
            disablePast
            maxDate={maxDate}
            minDate={minDate}
            onClose={() => {
              setOpen(false);
              onClose();
            }}
            disabled={disabled}
            label={
              <InputLabel className={`${isError ?? classes.inputLabelError}`} htmlFor={id}>
                {label}
              </InputLabel>
            }
            slots={{
              openPickerIcon: () => <SVGImage imageName={ImagesFileNames.iconCalendarSvg} />,
            }}
            slotProps={{
              textField: {
                id,
                name: input?.name,
                variant: 'standard',
                fullWidth: true,
                className: `${classes.datepickerTextfield} ${
                  isError ?? classes.datepickerTextfieldError
                }`,
                helperText,
                onKeyDown: (e) => {
                  if (e.key !== 'Tab') e.preventDefault();
                },
                onClick: (e) => {
                  e.preventDefault();
                  setOpen(true);
                },
                inputProps: {
                  ...props.inputProps,
                  'aria-describedby': `${input?.name}${i18n({
                    inputHelperTextConstants: 'HELPER_TEXT_SUFFIX',
                  })} ${inlineErrorId}`,
                },
              },
              layout: {
                className: `${classes.datepickerLayout} ${
                  isError ?? classes.datepickerLayoutError
                }`,
              },
              inputAdornment: { position: 'start' },
            }}
          />
        </LocalizationProvider>
        {isError && (
          <FormHelperText
            data-test="date-picker-inline-error"
            id={inlineErrorId}
            error
            aria-live="assertive"
            className={classes.errorLabel}
          >
            {meta.error}
          </FormHelperText>
        )}
      </div>
    );
  }
) as typeof React.Component;

const FormControlLabelFix = withStyles({
  root: {},
  label: {
    // Clicking on nested elements of radio button label prevents the value
    // from changing. Note that this would break any labels with nested links.
    pointerEvents: 'none',
  },
})(FormControlLabel);

type RadioOption = {
  disabled?: boolean;
  key: string;
  label: React.ReactNode;
  value: string;
  secondaryLabel?: React.ReactNode;
  ariaLabel?: string;
} & StyleProps;

export type RadioFieldProps = {
  ariaLabelledBy?: string;
  ariaRequired?: boolean;
  ['data-test']?: string;
  disabled?: boolean;
  tableRowClasses?: string;
  tableCellClasses?: string;
  id: string;
  label: string;
  labelClass: string;
  options: RadioOption[];
  placeholder: string;
  selectedValue?: string;
  showLabel: boolean;
  isHeaderRequired?: boolean;
};

type RadioButtonTableProps = FieldRenderProps<'radio'> &
  RadioFieldProps & {
    screenReaderLabel?: string;
  };

export function RadioButtonTable(props: RadioButtonTableProps) {
  const {
    screenReaderLabel,
    label,
    input,
    selectedValue,
    options,
    tableRowClasses,
    tableCellClasses,
    isHeaderRequired,
  } = props;

  const classes = useRadioStyles();

  return (
    <TableRow className={tableRowClasses}>
      {isHeaderRequired && (
        <TableCell classes={{ root: classes.formLabelRoot }}>
          {screenReaderLabel && <ScreenReaderText>{screenReaderLabel}</ScreenReaderText>}
          <StandardText aria-hidden={!!screenReaderLabel}>{label}</StandardText>
        </TableCell>
      )}
      {options.map((option) => {
        const checked = option.value === selectedValue;
        return (
          <TableCell key={`${label}${option.value}`} className={tableCellClasses}>
            <Radio
              value={option.value}
              checked={checked}
              name={input.name}
              onChange={input.onChange}
              inputProps={{ 'aria-label': option.ariaLabel }}
              disableRipple
              classes={{ root: classes.radio }}
            />
            {!isHeaderRequired && option.label && (
              <StandardText aria-hidden={!!screenReaderLabel}>{option.label}</StandardText>
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

export const RadioField = (props: FieldRenderProps<'radio'> & RadioFieldProps) => {
  const {
    ariaLabelledBy,
    ariaRequired,
    label,
    labelClass,
    options,
    input,
    placeholder,
    disabled,
    selectedValue,
    showLabel,
    'data-test': dataTest,
  } = props;
  const classes = useRadioStyles();

  const radioOptions = disabled
    ? options.map((option: RadioOption) => ({
        disabled: true,
        ...option,
      }))
    : options;

  const checkedClasses = { label: classes.checkedLabel } as const;

  return (
    <FormControl component="fieldset" aria-labelledby={ariaLabelledBy}>
      {showLabel && label ? (
        <FormLabel component="legend" classes={{ root: labelClass }}>
          {label}
        </FormLabel>
      ) : null}
      <RadioGroup
        {...input}
        data-test={dataTest}
        aria-label={!showLabel ? label : undefined} // aria-label is not necessary if we are rendering a <legend> element
        aria-required={ariaRequired}
      >
        {placeholder ? (
          <FormControlLabelFix
            value=""
            disabled
            control={<Radio classes={{ root: classes.radio }} />}
            label={placeholder}
            // @ts-ignore
            disableRipple
          />
        ) : null}
        {radioOptions.map((option) => {
          const checked = option.value === selectedValue;
          const optionClasses = checked ? { ...checkedClasses, ...option.classes } : option.classes;
          const inputLabel = option.value.replace(/\s/g, '');
          const strippedInputLabel = `${inputLabel}LabelId`;
          return (
            <FormControlLabelFix
              htmlFor={strippedInputLabel}
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              checked={checked}
              control={
                <Radio
                  id={strippedInputLabel}
                  inputProps={{ 'aria-required': ariaRequired }}
                  classes={{ root: classes.radio }}
                  disableRipple
                />
              }
              classes={optionClasses}
              label={
                <>
                  <BodyTextMedium>{option.label}</BodyTextMedium>
                  {option.secondaryLabel && (
                    <div className={classes.secondaryLabel}>{option.secondaryLabel}</div>
                  )}
                </>
              }
              data-test={`${kebabCase(option.label)}-term`}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export type SelectFieldProps = {
  ['data-test']?: string;
  ['data-test-label']?: string;
  ariaRequired?: boolean;
  disabled?: boolean;
  helperText: string;
  id: string;
  label: string;
  labelId: string;
  options: any[];
  placeholder?: string;
  required: boolean;
};

export const RenderSelectField = forwardRef(
  (
    props: FieldRenderProps<'select'> & SelectFieldProps & StyleProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const {
      'data-test': dataTest,
      'data-test-label': dataTestLabel,
      ariaRequired,
      ariaDisabled,
      helperText,
      id,
      input,
      label,
      labelId,
      onChange,
      options,
      placeholder = SelectField.GENERIC,
      required,
      classes,
      meta: { touched, active, error, dirtySinceLastSubmit, submitError },
      ...rest
    } = props;
    const displayError = !dirtySinceLastSubmit && submitError ? submitError : error;
    return (
      <Select
        {...input}
        {...rest}
        id={id}
        label={label}
        labelId={labelId}
        data-test={dataTest}
        data-test-label={dataTestLabel}
        fullWidth
        error={touched && !active && !!displayError}
        helperText={touched && !active && displayError ? displayError : helperText}
        aria-invalid={touched && !!displayError}
        classes={classes}
        FormHelperTextProps={{
          error: touched && !active && !!displayError,
          id: `${id}${i18n({ inputHelperTextConstants: 'HELPER_TEXT_SUFFIX' })}`,
          component: ErrorWrapper,
        }}
        ref={ref}
        inputProps={{
          'data-test': dataTest,
          'aria-required': required || ariaRequired,
          'aria-describedby': `${id}${i18n({ inputHelperTextConstants: 'HELPER_TEXT_SUFFIX' })}`,
          'aria-disabled': ariaDisabled,
        }}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
            aria-label={option.ariaLabel}
          >
            {option.name}
          </option>
        ))}
      </Select>
    );
  }
);

export const RenderMenuItemSelectField = forwardRef((props: FieldRenderProps<'select'>) => {
  const {
    'data-test': dataTest,
    ariaRequired,
    ariaDisabled,
    ariaLabel,
    id,
    input,
    disabledMenuItem,
    defaultVal,
    inputLabelText,
    disabled,
    disablePlaceholder,
    onChange,
    options,
    placeholder = SelectField.GENERIC,
    required,
    classes,
    meta,
    ...rest
  } = props;

  return (
    <ResizableSelect
      {...input}
      {...rest}
      id={id}
      data-test={dataTest}
      fullWidth
      displayEmpty
      disabled={disabled}
      value={defaultVal}
      inputLabelText={inputLabelText}
      classes={{ root: classes, icon: classes }}
      inputProps={{
        'data-test': dataTest,
        'aria-required': required || ariaRequired,
        'aria-describedby': `${id}${i18n({ inputHelperTextConstants: 'HELPER_TEXT_SUFFIX' })}`,
        'aria-disabled': ariaDisabled,
        'aria-label': ariaLabel,
        'aria-readonly': ariaDisabled,
      }}
    >
      <MenuItem value="" disabled={disablePlaceholder}>
        {placeholder}
      </MenuItem>
      {options.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          disabled={option.disabled}
          aria-label={option.ariaLabel}
        >
          {option.name}
        </MenuItem>
      ))}
    </ResizableSelect>
  );
});

const handleCheckboxFormError = (helperTextError: string, classes) => {
  return (
    <FormHelperText
      data-test="checkbox-inline-error"
      error
      aria-live="assertive"
      className={classes.errorLabel}
    >
      <Icon className="pr-2" icon={CIRCLE_EXCLAMATION} />
      {helperTextError}
    </FormHelperText>
  );
};

const handleCheckboxFormErrorBCL = (helperTextError: string, showIcon = true) => {
  return (
    <p className="text-error py-1">
      {showIcon && <Icon className="pr-2" icon={CIRCLE_EXCLAMATION} />}
      {helperTextError}
    </p>
  );
};

type FinalFormCheckProps = FieldRenderProps<true> & CheckProps;

export const RenderCheck = ({
  ariaLabel,
  ariaRequired,
  className,
  'data-test': dataTest,
  disabled,
  id,
  input,
  inputClassName,
  label,
  labelId,
  showActiveState,
  meta: { active, dirtySinceLastSubmit, error, submitError, touched },
}: FinalFormCheckProps): React.ReactElement => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useCheckboxStyles();
  const checkboxClass = checkBoxFormError();
  const hasSubmitError = !dirtySinceLastSubmit && !!submitError;
  const hasError = (touched && !active && !!error) || hasSubmitError;
  const helperTextError = hasSubmitError ? submitError : error;

  return (
    <div className={className}>
      <FormControlLabel
        {...input}
        id={labelId}
        checked={input.checked}
        disabled={disabled}
        control={
          <MUIFinalFormCheckbox
            disableRipple
            checkedIcon={
              <SVGImage
                iconComponent={
                  <CheckboxCheckedIconSVG
                    className={classes.checkedIconSVG}
                    aria-hidden="true"
                    role="img"
                  />
                }
              />
            }
            id={id}
            className={classnames(inputClassName, {
              [classes.showActiveCheckbox]: active && showActiveState,
            })}
            color="primary"
            data-test={dataTest}
            inputProps={{
              'aria-label': ariaLabel,
              'aria-labelledby': labelId,
              'aria-required': ariaRequired,
              // @ts-ignore
              'data-test': dataTest,
            }}
          />
        }
        label={label}
      />
      {!!hasError && handleCheckboxFormError(helperTextError, checkboxClass)}
    </div>
  );
};

export const RenderCheckBCL = ({
  ariaLabel,
  ariaRequired,
  'data-test': dataTest,
  disabled,
  id,
  input,
  label,
  showErrorAbove,
  showErrorIcon = true,
  meta: { dirtySinceLastSubmit, error, submitError, touched },
}: FinalFormCheckProps): React.ReactElement => {
  const hasSubmitError = !dirtySinceLastSubmit && !!submitError;
  const hasError = (touched && !!error) || hasSubmitError;
  const helperTextError: string = hasSubmitError ? submitError : error;

  const { checked, onChange, name, onBlur, onFocus } = input;

  const Error = () => !!hasError && handleCheckboxFormErrorBCL(helperTextError, showErrorIcon);

  return (
    <div className="py-2">
      {showErrorAbove && <Error />}
      <Checkbox
        id={id}
        name={name}
        checked={checked}
        label={label}
        onCheckedChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        aria-label={ariaLabel}
        aria-required={ariaRequired}
        data-test={dataTest}
      />
      {!showErrorAbove && <Error />}
    </div>
  );
};

type NoPhoneCheckProps = {
  ariaRequired: boolean;
  onChange?: (arg1: React.ChangeEvent<any>) => void;
};

export const NoPhoneCheck = ({
  input,
  label,
  labelId,
  onChange,
  ariaRequired,
  ...rest
}: FinalFormCheckProps & NoPhoneCheckProps) => {
  const classes = useNoPhoneCheckStyles();
  const checkBoxClasses = useCheckboxStyles();
  return (
    <FormControl component="fieldset" className={classes.phoneCheckField}>
      <FormControlLabel
        id={labelId}
        control={
          <MUIFinalFormCheckbox
            disableRipple
            {...rest}
            checkedIcon={
              <SVGImage
                iconComponent={
                  <CheckboxCheckedIconSVG
                    className={checkBoxClasses.checkedIconSVG}
                    aria-hidden="true"
                    role="img"
                  />
                }
              />
            }
            onChange={(e) => {
              input.onChange(e);
              if (onChange) {
                onChange(e);
              }
            }}
            value="noPhoneNumber"
            classes={{
              root: classes.checkboxOverride,
            }}
            color="primary"
            data-test="noPhoneNumber-check"
            inputProps={{
              'aria-labelledby': labelId,
              'aria-required': ariaRequired,
            }}
          />
        }
        label={label}
        data-test="noPhoneNumber-container"
        classes={{
          root: classes.checkboxFormControlOverride,
          label: classes.checkboxLabel,
        }}
      />
    </FormControl>
  );
};

const ElectronicAgreementCheck: React.ComponentType<FinalFormCheckProps> = ({
  disabled,
  input,
  label,
  labelId,
  required,
  classes = {},
  showValidationError,
  meta: { active, dirtySinceLastSubmit, error, submitError, touched },
  ...rest
}) => {
  const inputClasses = useCheckboxLabelStyles();
  const checkBoxClasses = useCheckboxStyles();
  const hasSubmitError = !dirtySinceLastSubmit && !!submitError;
  const hasError = (touched && !active && !!error && showValidationError) || hasSubmitError;
  const helperTextError = hasSubmitError ? submitError : error;

  return (
    <div className={classes.checkboxContainer}>
      <FormControlLabel
        {...input}
        disabled={disabled}
        id={labelId}
        classes={{ label: classes.achLabel }}
        checked={input.checked}
        control={
          <MUIFinalFormCheckbox
            disableRipple
            {...rest}
            checkedIcon={
              <SVGImage
                iconComponent={
                  <CheckboxCheckedIconSVG
                    className={checkBoxClasses.checkedIconSVG}
                    aria-hidden="true"
                    role="img"
                  />
                }
              />
            }
            className={classnames(inputClasses.inputCheckbox, {
              [inputClasses.showActiveCheckbox]: active,
            })}
            color="primary"
            inputProps={{ 'aria-labelledby': labelId }}
          />
        }
        label={label}
        data-test="terms-agreement"
      />
      {!!hasError && handleCheckboxFormError(helperTextError, inputClasses)}
    </div>
  );
};

export const RenderConfirmedACHCheck: React.ComponentType<FinalFormCheckProps> = (props) => (
  <ElectronicAgreementCheck
    {...props}
    label={
      <>
        I confirm that I am able to access and view the&nbsp;
        <InlineLinkText
          component="a"
          data-test="ach-agreement-link"
          data-track-title={clickTrack.addExternalAccount.agreement}
          href={props.disabled ? undefined : Routes.ACH_EXTERNAL_AGREEMENT_PDF}
          rel="noopener noreferrer"
          target="_blank"
        >
          Authorization Agreement for Electronic (ACH) Debits and Credits
        </InlineLinkText>
        , and I agree to its terms.
      </>
    }
  />
);

export const renderElectronicDeliveryCheck: React.ComponentType<FinalFormCheckProps> = (props) => (
  <ElectronicAgreementCheck
    {...props}
    label={
      <>
        I confirm that I am able to access and view the&nbsp;
        <InlineLinkText
          component="a"
          data-test="electronic-delivery-link"
          href={
            props.disabled
              ? undefined
              : 'https://www.synchronybank.com/sites/syfbank/documents/electronic-delivery-terms-conditions.pdf'
          }
          rel="noopener noreferrer"
          target="_blank"
        >
          Electronic Delivery Terms and Conditions
        </InlineLinkText>
        , and I consent to the electronic delivery of statements, disclosures and notices about my
        accounts.
      </>
    }
  />
);

const useFileFieldStyles = makeStyles({
  root: {
    paddingLeft: 0,
    lineHeight: 1.29,
  },
});

type FileFieldProps = FieldRenderProps<never, HTMLInputElement> & {
  helperText?: string;
};

export const RenderFileField = React.forwardRef<HTMLInputElement, FileFieldProps>((props, ref) => {
  // Don't pass value to the field because it is not a string and will cause an error
  const {
    input: { value, ...input },
    helperText,
    meta: { error },
    ...rest
  } = props;
  // lowercase component name is preferred here
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useFileFieldStyles();
  const displayText = error || helperText;
  return (
    <>
      <input type="file" ref={ref} {...input} {...rest} />
      {displayText && (
        <ErrorWrapper error={!!error} classes={{ root: classes.root }}>
          {displayText}
        </ErrorWrapper>
      )}
    </>
  );
});

export const FinalFormComponents = {
  RenderBirthDateField,
  RenderCheck,
  RenderConfirmedACHCheck,
  RenderEmailField,
  RenderFileField,
  RenderRadioField: RadioField,
  RenderSelectField,
  RenderMenuItemSelectField,
  RenderSSNField,
  RenderTelephoneField,
  RenderPhoneTypeField,
  NoPhoneCheck,
  RenderTextField,
  RenderZipcodeField,
};
