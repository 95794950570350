import React, { useState } from 'react';
import { ReduxState } from 'src/reducers';
import { Grid, Hidden } from '@material-ui/core';
import { connect } from 'react-redux';
import { History } from 'history';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { ProductState } from '../products/products.reducer';
import Page from '../../components/page/page';
import LeftPanelGridItem, { LeftPanelHeader } from '../../components/pageLayout/LeftPanelGridItem';
import ArrowLink from '../../components/links/arrowLink';
import Routes, { QueryParams } from '../routes/routes.constants';
import { clickTrack, clickTrackType } from '../../analytics/clickTracking.constants';
import type { MessageThread } from './viewInbox.constants';
import type { Dispatch } from '../../utilities/types';
import { ViewInboxFlashMessageTexts, ViewInboxPageTexts } from './viewInbox.constants';
import {
  InlineLinkText,
  StandardTextEmphasis,
  TransactionSubtitle,
} from '../../components/typography/typography';
import { useViewInboxContainerStyles } from './viewInbox.styles';
import useEffectOnce from '../../utilities/reactHooks';
import ButtonLink from '../../components/links/buttonLink';
import ContentGridContainerAndItem from '../../components/pageLayout/ContentGridContainerAndItem';
import TileListSubtitles from '../../components/tileList/tileListSubtitles';
import TileList from '../../components/tileList/tileList';
import MessagesTileListItem from './messagesTileListItem';
import Error from '../../components/error/error';
import { fetchInboxMessages } from '../messages/messages.service';
import { NoActivityMessage } from '../../components/tileList/noActivityTileList';
import type { InboxLocationShape } from '../messages/messages.constants';
import IgniteFlashMessage from '../../components/flashMessage/IgniteFlashMessage';
import { FlashMessageVariant } from '../../components/flashMessage/flashMessage.constants';
import {
  ANALYTICS_VIEW_INBOX_FAILURE,
  ANALYTICS_VIEW_INBOX_SUCCESS,
} from '../../analytics/actions';
import FooterInfo from '../../components/footer/footer.constants';
import Tile from '../../components/tileList/tile';

type Props = {
  location: InboxLocationShape;
  history: History;
};

const pageViewActions = Object.freeze({
  viewInboxSuccess: ANALYTICS_VIEW_INBOX_SUCCESS,
  viewInboxFailure: ANALYTICS_VIEW_INBOX_FAILURE,
});

type PageViewAction = typeof pageViewActions[keyof typeof pageViewActions];

type DispatchProps = {
  recordAnalyticsPageView: (pageViewAction: PageViewAction) => void;
};
type ViewInboxMapStateProps = {
  affinityCompanyCode: ProductState['affinityCompanyCode'];
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  recordAnalyticsPageView: (pageViewAction: PageViewAction) => dispatch({ type: pageViewAction }),
});
const mapStateToProps = (state: ReduxState): ViewInboxMapStateProps => ({
  affinityCompanyCode:
    state.applications.customerInfo && state.applications.customerInfo.affinityCompanyCode,
});

type AllProps = Props & ViewInboxMapStateProps & DispatchProps;

const renderMessages = () => (message: MessageThread) =>
  <MessagesTileListItem messageThread={message} />;

const emptyTile = (text) => (
  <Tile>
    <Hidden xsDown>
      <td colSpan={2}>
        <TransactionSubtitle>{text}</TransactionSubtitle>
      </td>
    </Hidden>
    <Hidden smUp>
      <td role="cell">
        <TransactionSubtitle>{text}</TransactionSubtitle>
      </td>
    </Hidden>
  </Tile>
);

const renderListItems = (messages: MessageThread[], classes: ClassNameMap) => {
  const renderTileListHeader = () => (
    <>
      <Hidden xsDown mdUp>
        <TileListSubtitles
          columnSizes={{ left: 4, middle: 3 }}
          middleColumn="Date"
          rightColumn="Topic"
          titleType="messages"
          data-test="messages-list-subtitles"
        />
      </Hidden>
      <Hidden smDown>
        <TileListSubtitles
          leftColumn="Date"
          middleColumn="Topic"
          columnSizes={{ left: 3, middle: 9 }}
          titleType="messages"
          data-test="messages-list-subtitles"
          inboxClass={classes.inboxHeaderPadding}
        />
      </Hidden>
    </>
  );

  return messages && messages.length > 0 ? (
    <>
      {renderTileListHeader()}
      <TileList
        component="ol"
        data-test="messages-list"
        list={messages}
        listItemRenderProp={renderMessages()}
        listItemComponent="li"
      />
    </>
  ) : (
    <TileList
      list={[NoActivityMessage.NO_MESSAGES]}
      listItemRenderProp={emptyTile}
      header={renderTileListHeader()}
    />
  );
};

export const ViewInbox = (props: AllProps) => {
  const { history, location, affinityCompanyCode, recordAnalyticsPageView } = props;
  const [successfullySubmitted] = useState(location.state && location.state.successfullySent);
  const classes = useViewInboxContainerStyles();
  const [isChatAvailable, setIsChatAvailable] = useState(false);
  const [messages, setMessages] = useState<MessageThread[] | null | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setError] = useState(false);

  const UISCODE_PARAM = `?${QueryParams.affinityCode}=${affinityCompanyCode || '0000000'}`;

  const fetchMessages = async () => {
    setError(false);
    setIsLoading(true);

    let response;
    try {
      response = await fetchInboxMessages();
      setMessages(response.messageThreads);
      recordAnalyticsPageView(pageViewActions.viewInboxSuccess);
    } catch (err) {
      setError(true);
      recordAnalyticsPageView(pageViewActions.viewInboxFailure);
    } finally {
      setIsLoading(false);
    }
  };

  useEffectOnce(() => {
    if (window.conciergeReady) {
      window.conciergeReady.then(() => {
        window.GoMoxie.conciergeV2.checkAvailability('chat', {}).then((response) => {
          setIsChatAvailable(response);
        });
      });
    }

    fetchMessages();
    if (location.state && location.state.successfullySent) {
      // Clear the location state so that if the page is reloaded via refresh or browser back, the success message is gone
      history.replace(location.pathname || window.location.pathname);
    }
  });

  return (
    <Page title={ViewInboxPageTexts.TITLE} loading={isLoading}>
      <LeftPanelGridItem>
        <LeftPanelHeader title={ViewInboxPageTexts.HEADER_TITLE} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {ViewInboxPageTexts.DESCRIPTION}
          </Grid>
          <Grid item xs={12}>
            <ButtonLink
              to={Routes.NEW_MESSAGE}
              data-track-title={clickTrack.viewInbox.compose_new_message}
              data-track-type={clickTrackType.BUTTON}
            >
              {ViewInboxPageTexts.SEND_MSG_BTN}
            </ButtonLink>
          </Grid>
          <Grid item xs={12} className={classes.marginTopContainer}>
            {ViewInboxPageTexts.FAQ_DESC}
          </Grid>
          <Grid item xs={12}>
            <ArrowLink
              to={`${FooterInfo.FAQ_URL}${UISCODE_PARAM}`}
              target="_blank"
              rel="noopener noreferrer"
              data-test="quick-links-FAQ"
            >
              {ViewInboxPageTexts.FAQ_LINK}
            </ArrowLink>
          </Grid>
          <Grid item xs={12} className={classes.marginTopContainer}>
            {ViewInboxPageTexts.LEARN_MORE_DESC}
          </Grid>
          <Grid item xs={12}>
            <ArrowLink
              to={`https://www.synchronybank.com/mobile-app/${UISCODE_PARAM}`}
              target="_blank"
              rel="noopener noreferrer"
              data-test="quick-links-learn-more"
            >
              {ViewInboxPageTexts.LEARN_MORE_LINK}
            </ArrowLink>
          </Grid>
        </Grid>
        <Grid container className={classes.contentContainer}>
          <Grid item xs={12}>
            {ViewInboxPageTexts.CHAT_WITH_US}
            {isChatAvailable ? (
              <InlineLinkText href="" data-moxie-start-engagement="chat" role="button">
                <StandardTextEmphasis>{ViewInboxPageTexts.AVAILABLE_LINK}</StandardTextEmphasis>
              </InlineLinkText>
            ) : (
              ViewInboxPageTexts.NOT_AVAILABLE
            )}
          </Grid>
        </Grid>
      </LeftPanelGridItem>

      <ContentGridContainerAndItem>
        {successfullySubmitted && !hasError && !isLoading && (
          <IgniteFlashMessage
            variant={FlashMessageVariant.SUCCESS}
            className={classes.flashMessage}
            focusMessage
          >
            {ViewInboxFlashMessageTexts.SUCCESS}
          </IgniteFlashMessage>
        )}

        <Grid item className={classes.messageContainer}>
          {hasError ? <Error center onClick={fetchMessages} /> : renderListItems(messages, classes)}
        </Grid>
      </ContentGridContainerAndItem>
    </Page>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewInbox);
