import { withStyles, CircularProgress } from '@material-ui/core';
import type { ReactNode, Ref } from 'react';
import React, { forwardRef, useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { ClassNameMap, StyleRules } from '@material-ui/styles';
import Button from 'bank-component-library/ui/atoms/Button';
import i18n from '../../strings/i18n';
import type { Account, StyleProps } from '../../utilities/types';
import colors from '../colors/colors';
import { LinkText, SubLinkText } from '../typography/typography';
import Routes, {
  accountDetailHelpers,
  showEpreferencesLink,
} from '../../containers/routes/routes.constants';
import styles from './headerLinks.styles';
import { formatProductDisplayTypeForScreenReader } from '../../utilities/a11y';
import HeaderIconLink from '../links/headerIconLink';
import { clickTrack } from '../../analytics/clickTracking.constants';
import pxToRem from '../../utilities/pxToRem';
import breakpoints from '../../styles/breakpoints';
import { buildAccountNameForAccount } from '../../formatters/buildAccountName';
import { isEligibleForStatements } from '../../containers/accountDashboard/accountDashboard.reducer';
import labelsConstants from '../cms/labels.constants';
import type { SVGComponent } from '../svg/svg';
import LeavingSiteConfirmationModal from '../modal/LeavingSiteConfirmationModal';
import { ModalText } from '../cms/blockText.constants';
import IconButton from '../buttons/iconButton';
import ImagesFileNames from '../../images';
import SVGImage from '../svgImage';
import { CloseIconSVG, NavMenuIconSVG } from '../svg/svg';

type HeaderLinkProps = {
  ['data-test']: string;
  ['data-track-title']?: string;
  classes: ClassNameMap;
  id?: string;
  showLightText?: boolean;
  text: string;
  isExpanded?: boolean;
  isHamburgerVisible: boolean;
  children?: ReactNode;
  pathname?: string;
  to: string;
  isRightMenu?: boolean;
  icon?: React.ReactElement<React.ComponentProps<SVGComponent>>;
};

type RightLeftProps = {
  ['data-test']?: string;
  isExpanded?: boolean;
  pathname: string;
  showLightText?: boolean;
  isHamburgerVisible: boolean;
  accounts: Account[];
};

type HeaderLinksLeftProps = RightLeftProps & {
  nextPageAvailable: boolean;
  accountIsLoading: boolean;
};

type HeaderLinksRightProps = RightLeftProps & {
  name: string | null | undefined;
  unreadMessages: number;
  isDashboardCaching?: boolean;
  onSignOff?: any;
  classes?: {
    [key: string]: string;
  };
};

export type SubLinkProps = {
  ['data-test']?: string;
  ['data-track-title']?: string;
  ['data-track-page']?: string;
  ['data-track-pagefunction']?: string;
  ['aria-label']?: string;
  classes: ClassNameMap;
  style?: {
    opacity: number;
  };
  pathname?: string;
  text: string;
  params?: {
    [name: string]: string;
  };
  id?: string;
  onClick?: () => void;
  accountId?: string;
  isExpanded: boolean;
  isHamburgerVisible: boolean;
  showLightText: boolean;
};
const isChildActive = (elementChildren: ReactNode, pathname) => {
  return React.Children.toArray(elementChildren).some((child) => {
    if (!child || !React.isValidElement<HeaderLinkProps>(child)) return false;
    if (Array.isArray(child.props.children)) {
      return isChildActive(child.props.children, pathname);
    }
    if (pathname && pathname.includes(Routes.STATEMENTS)) return pathname.includes(child.props.to);
    return child.props.to === pathname;
  });
};
const isLightTest = (showLightText) => (showLightText ? colors.white : colors.text);
export const HeaderLink = withStyles(styles)(
  forwardRef((headerLinkProps: HeaderLinkProps, ref: Ref<HTMLLIElement>) => {
    const {
      'data-test': dataTest,
      'data-track-title': dataTrackTitle,
      children = null,
      classes = {},
      id,
      isExpanded,
      pathname,
      showLightText,
      text,
      to,
      isRightMenu = false,
      icon,
    } = headerLinkProps;

    const isActive = pathname === to || isChildActive(children, pathname);

    return (
      <li
        className={classnames(
          classes.headerLinkTray,
          isRightMenu ? classes.headerLinksRight : null
        )}
        data-test={`${dataTest}-list`}
        ref={ref}
      >
        <div
          className={classnames(classes.menuLink, {
            [classes.menuLinkRight]: isRightMenu,
            [classes.menuLinkWithIcon]: !!icon,
          })}
          id={id ? `div${id}` : 'div'}
          data-test={dataTest}
          style={{ color: isLightTest(showLightText) }}
        >
          {icon}
          <LinkText
            id={id}
            textColor={isLightTest(showLightText)}
            data-track-title={dataTrackTitle}
          >
            <span className={isActive ? classes.activeHeaderLink : ''}>{text}</span>
          </LinkText>
        </div>
        <ul>
          {React.Children.map(
            children,
            (child) =>
              React.isValidElement(child) &&
              React.cloneElement(child, {
                style: { opacity: isExpanded ? 1 : 0 },
              } as Partial<HeaderLinkProps>)
          )}
        </ul>
      </li>
    );
  })
);

const HeaderSubLink = withStyles(styles)(
  forwardRef(
    (
      subLinkProps: SubLinkProps & {
        to: string;
      },
      ref: Ref<HTMLAnchorElement>
    ) => {
      const {
        style = {},
        classes = {},
        onClick,
        isExpanded,
        isHamburgerVisible,
        pathname,
        to,
        id,
        'aria-label': ariaLabel,
        'data-test': dataTest,
        'data-track-title': dataTrackTitle,
        'data-track-page': dataTrackPage,
        'data-track-pagefunction': dataTrackPageFunction,
        text,
      } = subLinkProps;
      const isActive = pathname === to;
      return (
        <li
          className={classnames(
            classes.subLinkItem,
            !isExpanded ? classes.subLinkItemInactive : ''
          )}
          style={style}
        >
          <Link
            className={classes.subLink}
            to={to}
            id={id}
            data-test={dataTest}
            data-track-title={dataTrackTitle}
            data-track-page={dataTrackPage}
            data-track-pagefunction={dataTrackPageFunction}
            onClick={onClick}
            tabIndex={isHamburgerVisible && !isExpanded ? -1 : 0}
            aria-label={ariaLabel || text}
            aria-current={isActive ? 'page' : undefined}
            ref={ref}
          >
            <SubLinkText isActive={isActive}>{text}</SubLinkText>
          </Link>
        </li>
      );
    }
  )
);

type MobileMenuToggleProps = {
  isMenuExpanded?: boolean;
  isDark?: boolean;
  menuToggle?: () => void;
  className: string;
};

export const MobileMenuToggle = withStyles(styles)(
  React.forwardRef<HTMLButtonElement, MobileMenuToggleProps & StyleProps>((props, ref) => {
    const {
      isMenuExpanded = false,
      className,
      classes = {},
      isDark = false,
      menuToggle = () => {
        /* This is intentional to avoid eslint issue i.e sonarqube critcal issue */
      },
    } = props;

    const NavMenuIconSVGFillValue = isDark ? colors.white : '';

    return (
      <IconButton
        aria-label="Menu"
        aria-owns="ham-menu"
        aria-expanded={isMenuExpanded}
        className={className}
        onClick={menuToggle}
        ref={ref}
      >
        {isMenuExpanded ? (
          <SVGImage iconComponent={<CloseIconSVG className={classes.closeIcon} role="img" />} />
        ) : (
          <SVGImage
            iconComponent={
              <NavMenuIconSVG
                className={classnames(classes.hamburgerIcon)}
                fill={NavMenuIconSVGFillValue}
                role="img"
              />
            }
          />
        )}
      </IconButton>
    );
  })
);

MobileMenuToggle.displayName = 'MobileMenu';

const loadingIndicatorStyles: StyleRules = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: pxToRem(3),
    [breakpoints.down('md')]: {
      paddingTop: 0,
      justifyContent: 'flex-start',
    },
  },
  root: {
    color: colors.optimumBlue,
    marginLeft: pxToRem(24),
    marginRight: pxToRem(4),
    [breakpoints.down('md')]: {
      marginLeft: pxToRem(16),
    },
  },
  text: {
    color: colors.steelGrey,
    fontSize: pxToRem(14),
  },
};

const LoadingIndicator = withStyles(loadingIndicatorStyles)((props: { classes: ClassNameMap }) => {
  const { classes = {} } = props;

  return (
    <div className={classes.container} data-test="navbar-accounts-loading">
      <CircularProgress className={classes.root} size={pxToRem(14)} />
      <div className={classes.text}>Loading Accounts</div>
    </div>
  );
});

export const HeaderLinksLeft = forwardRef(
  (props: HeaderLinksLeftProps, firstLinkRef: Ref<HTMLAnchorElement>) => {
    const {
      showLightText,
      isExpanded,
      isHamburgerVisible,
      pathname,
      accounts,
      nextPageAvailable,
      accountIsLoading,
    } = props;
    const shouldDisplayHeaderAccounts = accounts?.length <= 5 && !nextPageAvailable;

    const renderAccountsSubLinks = () => {
      const renderAccountSubLink = (account: Account) => {
        const accountName = buildAccountNameForAccount(account);
        const accountDisplayName =
          accountName.length > 12 ? `${accountName.substring(0, 12)}...` : accountName;
        const ariaLabel = formatProductDisplayTypeForScreenReader(account);
        const to = accountDetailHelpers.buildAccountDetailsUrl(
          account.accountType,
          account.accountId
        );
        return (
          <HeaderSubLink
            aria-label={ariaLabel}
            text={accountDisplayName}
            showLightText={showLightText}
            pathname={pathname}
            to={to}
            key={account.accountId}
            accountId={account.accountId}
            isExpanded={isExpanded}
            isHamburgerVisible={isHamburgerVisible}
          />
        );
      };

      if (accountIsLoading && shouldDisplayHeaderAccounts) return <LoadingIndicator />;
      return shouldDisplayHeaderAccounts && accounts.map(renderAccountSubLink);
    };
    const [showLeavingSiteModal, setShowLeavingSiteModal] = useState(false);
    const OPEN_NEW_ACCOUNT_CTA = 'Open New Account';
    const renderLeavingSiteConfirmationModal = () => {
      return (
        <LeavingSiteConfirmationModal
          to={Routes.ATM_LOCATOR}
          onExit={() => setShowLeavingSiteModal(false)}
          visible={showLeavingSiteModal}
          modalBody={ModalText.ATM_LOCATOR_WARNING_MODAL_BODY}
        />
      );
    };
    const onClickAtmLocator = () => {
      setShowLeavingSiteModal(true);
    };
    return (
      <>
        <HeaderLink
          to={Routes.DASHBOARD}
          text="My Accounts"
          id="myAccounts"
          data-test="navbar-my-accounts"
          showLightText={showLightText}
          isExpanded={isExpanded}
          pathname={pathname}
          isHamburgerVisible={isHamburgerVisible}
        >
          <HeaderSubLink
            to={Routes.DASHBOARD}
            text="Dashboard"
            id="dashboard"
            data-test="navbar-dashboard"
            showLightText={showLightText}
            pathname={pathname}
            isExpanded={isExpanded}
            isHamburgerVisible={isHamburgerVisible}
            ref={firstLinkRef}
          />
          {renderAccountsSubLinks()}
        </HeaderLink>
        <HeaderLink
          to=""
          text="Move Money"
          id="moveMoney"
          data-test="navbar-move-money"
          showLightText={showLightText}
          isExpanded={isExpanded}
          pathname={pathname}
          isHamburgerVisible={isHamburgerVisible}
        >
          <HeaderSubLink
            to={Routes.NEW_TRANSFER}
            id="newTransfer"
            data-test="navbar-new-transfer"
            text={labelsConstants.NEW_TRANSFER}
            showLightText={showLightText}
            pathname={pathname}
            isExpanded={isExpanded}
            isHamburgerVisible={isHamburgerVisible}
          />
          <HeaderSubLink
            to={Routes.TRANSFERS_DASHBOARD}
            id="transfers"
            data-test="navbar-transfers"
            text="Transfers"
            showLightText={showLightText}
            pathname={pathname}
            isExpanded={isExpanded}
            isHamburgerVisible={isHamburgerVisible}
          />
          <HeaderSubLink
            to={Routes.EXTERNAL_ACCOUNTS}
            text="External Accounts"
            id="externalAccounts"
            data-test="navbar-external-accounts"
            showLightText={showLightText}
            pathname={pathname}
            isExpanded={isExpanded}
            isHamburgerVisible={isHamburgerVisible}
          />
          {window.__config__.ZELLE_ENABLED === 'true' && (
            <HeaderSubLink
              to={Routes.ZELLE}
              text="Zelle"
              id="zelle"
              data-test="navbar-zelle"
              showLightText={showLightText}
              pathname={pathname}
              isExpanded={isExpanded}
              isHamburgerVisible={isHamburgerVisible}
            />
          )}
        </HeaderLink>
        <HeaderLink
          to=""
          text="More Services"
          id="moreServices"
          data-test="navbar-more-services"
          showLightText={showLightText}
          isExpanded={isExpanded}
          pathname={pathname}
          isHamburgerVisible={isHamburgerVisible}
        >
          <HeaderSubLink
            to={Routes.CANCEL_CARD}
            text={labelsConstants.MANAGE_CARDS}
            id="cancelCard"
            data-test="navbar-cancel-card"
            data-track-title={clickTrack.header.cancelCard}
            showLightText={showLightText}
            pathname={pathname}
            isExpanded={isExpanded}
            isHamburgerVisible={isHamburgerVisible}
          />

          <HeaderSubLink
            to="#"
            text={labelsConstants.ATM_LOCATOR}
            id="atmLocator"
            data-test="navbar-atm-locator"
            onClick={onClickAtmLocator}
            data-track-title={clickTrack.header.atm_locator}
            showLightText={showLightText}
            pathname={pathname}
            isExpanded={isExpanded}
            isHamburgerVisible={isHamburgerVisible}
          />
          <HeaderSubLink
            to={Routes.ALERTS}
            text={labelsConstants.ALERTS}
            id="alerts"
            data-test="navbar-alerts"
            data-track-title={clickTrack.header.alerts}
            showLightText={showLightText}
            pathname={pathname}
            isExpanded={isExpanded}
            isHamburgerVisible={isHamburgerVisible}
          />

          {window.__config__.IGNITE_BENEFICIARIES_ENABLED === 'true' && (
            <HeaderSubLink
              to={Routes.BENEFICIARIES}
              text={labelsConstants.BENEFICIARIES}
              id="beneficiaries"
              data-test="navbar-beneficiaries"
              data-track-title={clickTrack.header.beneficiaries}
              showLightText={showLightText}
              pathname={pathname}
              isExpanded={isExpanded}
              isHamburgerVisible={isHamburgerVisible}
            />
          )}
        </HeaderLink>

        <HeaderLink
          to=""
          text="Explore Products"
          id="exploreProducts"
          data-test="navbar-explore-products"
          showLightText={showLightText}
          isExpanded={isExpanded}
          pathname={pathname}
          isHamburgerVisible={isHamburgerVisible}
        >
          <HeaderSubLink
            to={Routes.EXPLORE_PRODUCTS}
            text={labelsConstants.SAVINGS_ACCOUNTS}
            id="savingsAccounts"
            data-test="navbar-savings-accounts"
            showLightText={showLightText}
            pathname={pathname}
            isExpanded={isExpanded}
            isHamburgerVisible={isHamburgerVisible}
          />
          {window.__config__.RATE_AND_TERMS === 'true' && (
            <HeaderSubLink
              to={Routes.RATE_AND_TERMS}
              text={labelsConstants.RATES_AND_TERMS}
              id="Rates and Terms"
              data-test="navbar-rate-and-terms"
              data-track-title={clickTrack.ratesAndTerms.rates}
              data-track-pagefunction={clickTrack.selectAProduct.rates}
              data-track-page={clickTrack.ratesAndTerms.view}
              showLightText={showLightText}
              pathname={pathname}
              isExpanded={isExpanded}
              isHamburgerVisible={isHamburgerVisible}
            />
          )}
          <li>
            <HeaderIconLink
              data-test="navbar-open-account"
              data-track-title={clickTrack.account.open_new_account}
              icon={<SVGImage imageName={ImagesFileNames.iconPlusCircleSvg} ariaHidden="true" />}
              linkText={OPEN_NEW_ACCOUNT_CTA}
              to={Routes.EAO}
              isExpanded={isExpanded}
              isHamburgerVisible={isHamburgerVisible}
            />
          </li>
        </HeaderLink>

        {renderLeavingSiteConfirmationModal()}
      </>
    );
  }
);

export const HeaderLinksRight = withStyles(styles)((props: HeaderLinksRightProps) => {
  const {
    showLightText,
    isExpanded,
    isHamburgerVisible,
    pathname,
    name,
    unreadMessages,
    accounts,
    isDashboardCaching,
    classes = {},
    onSignOff,
  } = props;

  return (
    <>
      {!isDashboardCaching && (
        <HeaderLink
          to=""
          text="Inbox"
          data-test="navbar-Inbox"
          data-track-title={clickTrack.header.inboxMenu}
          showLightText={showLightText}
          isExpanded={isExpanded}
          isHamburgerVisible={isHamburgerVisible}
          pathname={pathname}
          isRightMenu
          icon={
            unreadMessages > 0 ? (
              <SVGImage
                imageName={ImagesFileNames.filledCircleSvg}
                fill={colors.synchronyGold}
                className={classes.inboxIcon}
              />
            ) : undefined
          }
        >
          <HeaderSubLink
            to={Routes.VIEW_INBOX}
            text={labelsConstants.VIEW_INBOX + (unreadMessages ? `(${unreadMessages})` : '')}
            id="viewInbox"
            data-test="navbar-viewInbox"
            data-track-title={clickTrack.header.message_center_inbox}
            showLightText={showLightText}
            pathname={pathname}
            isExpanded={isExpanded}
            isHamburgerVisible={isHamburgerVisible}
          />
          <HeaderSubLink
            to={Routes.NEW_MESSAGE}
            text={labelsConstants.SEND_MESSAGE}
            id="sendMessage"
            data-test="navbar-send-message"
            data-track-title={clickTrack.header.compose_new_message_from_header}
            showLightText={showLightText}
            pathname={pathname}
            isExpanded={isExpanded}
            isHamburgerVisible={isHamburgerVisible}
          />
          <HeaderSubLink
            to={Routes.DOCUMENTS}
            text={labelsConstants.DOCUMENTS}
            id="documents"
            data-test="navbar-documents"
            data-track-title={clickTrack.header.documents}
            data-track-page={clickTrack.general.landing_page}
            showLightText={showLightText}
            pathname={pathname}
            isExpanded={isExpanded}
            isHamburgerVisible={isHamburgerVisible}
          />
          {isEligibleForStatements(accounts) && (
            <HeaderSubLink
              to={Routes.STATEMENTS}
              text="Statements"
              id="statements"
              data-test="navbar-statements"
              data-track-title={clickTrack.header.statements}
              showLightText={showLightText}
              pathname={pathname}
              isExpanded={isExpanded}
              isHamburgerVisible={isHamburgerVisible}
            />
          )}
          {showEpreferencesLink(accounts) && (
            <HeaderSubLink
              to={Routes.PREFERENCES}
              text={i18n({ dashboard: 'ePreferences' })}
              id="goPaperless"
              data-test="navbar-go-paperless"
              data-track-title={clickTrack.header.go_paperless}
              showLightText={showLightText}
              pathname={pathname}
              isExpanded={isExpanded}
              isHamburgerVisible={isHamburgerVisible}
            />
          )}
        </HeaderLink>
      )}

      {!isDashboardCaching && (
        <HeaderLink
          to={Routes.PROFILE}
          text={name || 'Profile'}
          data-test="navbar-profile"
          data-track-title={clickTrack.header.editProfile}
          showLightText={showLightText}
          isExpanded={isExpanded}
          isHamburgerVisible={isHamburgerVisible}
          pathname={pathname}
          isRightMenu
        >
          <HeaderSubLink
            to={Routes.PROFILE}
            text="Profile"
            id="myprofile"
            data-test="navbar-sub-profile"
            data-track-title={clickTrack.header.editProfile}
            showLightText={showLightText}
            pathname={pathname}
            isExpanded={isExpanded}
            isHamburgerVisible={isHamburgerVisible}
          />
          {window.__config__.SECURITY_ENABLED === 'true' && (
            <HeaderSubLink
              to={Routes.SECURITY}
              text={labelsConstants.SECURITY}
              id="security"
              data-test="navbar-security"
              data-track-title={clickTrack.header.security}
              showLightText={showLightText}
              pathname={pathname}
              isExpanded={isExpanded}
              isHamburgerVisible={isHamburgerVisible}
            />
          )}
          <HeaderSubLink
            to={Routes.REWARDS}
            text={labelsConstants.REWARDS}
            id="myRewards"
            data-test="navbar-my-rewards"
            data-track-title={clickTrack.header.rewards}
            showLightText={showLightText}
            pathname={pathname}
            isExpanded={isExpanded}
            isHamburgerVisible={isHamburgerVisible}
          />
        </HeaderLink>
      )}

      <li
        className={classnames(
          classes.dashboardCachingHeader,
          isDashboardCaching ? classes.cachedMenu : ''
        )}
        data-test="button-signout-holder"
      >
        {isDashboardCaching && (
          <div
            className={classnames(classes.dashboardCachingElements, !name ? classes.noProfile : '')}
            data-test="cached-name-holder"
          >
            <span data-test="name-cached-dashboard" className={classes.dashboardCachingText}>
              {name}
            </span>
          </div>
        )}

        <div className={classnames({ [classes.cachingSignOutButton]: isDashboardCaching })}>
          <Button
            variant={showLightText ? 'filled' : 'outlined'}
            color={showLightText ? 'secondary' : 'primary'}
            size="small"
            disabled={false}
            onClick={onSignOff}
            data-test="navbar-logout"
            data-track-title="sign-off"
            tabIndex={isHamburgerVisible && !isExpanded ? -1 : 0}
          >
            {labelsConstants.SIGN_OUT}
          </Button>
        </div>
      </li>
    </>
  );
});
