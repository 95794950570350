import { StyleRules } from '@material-ui/core';
import pxToRem from '../../utilities/pxToRem';
import type { Theme } from '../../utilities/types';
import Colors from '../../components/colors/colors';
import tw from '../../utilities/tw';

interface BankListItemStyles {
  bankFlexItem: string;
}

export const bankListItemStyles: BankListItemStyles = {
  bankFlexItem: tw`flex flex-wrap justify-left gap-x-1.5 gap-y-2.5 p-1 mb-2.5 lg:justify-space-between `,
};

export default (theme: Theme): StyleRules => ({
  root: {},
  termsLink: {
    display: 'inline',
  },
  fakeLink: {
    background: 'none',
    color: '#016899',
    border: 'none',
    padding: '0',
    font: 'inherit',
    fontWeight: 900,
    textDecoration: 'none',
    display: 'inline',
    cursor: 'pointer',
    letterSpacing: '1px',
    '&:visited': {},
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:active': {
      textDecoration: 'underline',
    },
    '&:focus': {
      textDecoration: 'underline',
    },
  },
  dialogActions: {
    float: 'right',
    marginTop: '0px',
  },
  dialogTitle: {
    fontSize: '1.5rem',
    lineHeight: '1.8rem',
    color: '#333740',
    textShadow: '0 2px 8px 0 rgba(0,0,0,0.2)',
    fontWeight: 'bold',
    paddingTop: '25px',
    paddingLeft: '50px',
    paddingRight: '20px',
  },
  dialogTitleText: {
    marginTop: '20px',
  },
  dialogContent: {
    paddingLeft: '50px',
    paddingRight: '70px',
    paddingBottom: '50px',
  },
  dialogContentText: {
    lineHeight: '1em',
  },
  closeIcon: {
    fontSize: '36px',
  },
  content: {
    font: 'inherit',
    backgroundColor: 'rgb(240, 240, 240)',
  },
  transfersOverviewContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: pxToRem(20),
  },
  transfersOverviewContainerSuccessful: {
    marginBottom: pxToRem(75),
  },
  transferIcon: {
    width: pxToRem(64),
    [theme.breakpoints.up('sm')]: {
      width: 'inherit',
    },
  },
  transferIconContainer: {
    margin: `0 ${pxToRem(18)}`,
    [theme.breakpoints.up('sm')]: {
      margin: `0 ${pxToRem(25)}`,
    },
  },
  overrideCtaContainer: {
    marginTop: pxToRem(80),
    marginBottom: pxToRem(60),
  },
  doneLink: {
    marginTop: pxToRem(10),
  },
  termsContainer: {
    marginBottom: pxToRem(70),
  },
  overrideCheckbox: {
    width: 'auto',
    height: 'auto',
    marginLeft: pxToRem(-3),
  },
  overrideFlashMessage: {
    maxWidth: '100%',
  },
  formTextBoxContainer: {
    marginBottom: pxToRem(30),
    marginTop: pxToRem(15),
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  noteForRequired: {
    marginTop: pxToRem(30),
  },
  bankIcon: {
    boxSizing: 'content-box',
    border: `${pxToRem(1)} solid ${Colors.lightGray}`,
    width: pxToRem(24),
    height: pxToRem(24),
    padding: pxToRem(2),
    marginRight: pxToRem(10),
    [theme.breakpoints.up('lg')]: {
      margin: 0,
    },
  },
  moreText: {
    marginTop: pxToRem(4),
  },
});
