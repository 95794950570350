import React from 'react';
import { Hidden } from '@material-ui/core';
import Tile from './tile';
import { TransactionSubtitle } from '../typography/typography';

const emptyTile = (text, classes) => (
  <Tile>
    <Hidden smDown>
      <td colSpan={3}>
        <TransactionSubtitle className={classes.noActivityMessageText}>{text}</TransactionSubtitle>
      </td>
    </Hidden>
    <Hidden mdUp>
      <td colSpan={2}>
        <TransactionSubtitle className={classes.noActivityMessageText}>{text}</TransactionSubtitle>
      </td>
    </Hidden>
  </Tile>
);

export default emptyTile;
