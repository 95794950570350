import React, { useState } from 'react';
import { withStyles, StyleRules } from '@material-ui/core';
import { kebabCase } from 'lodash';
import { fetchDisclosures } from '../../utilities/fetchDisclosures';
import type { Term, Product, ProductCode } from '../../containers/products/products.reducer';
import pxToRem from '../../utilities/pxToRem';
import colors from '../colors/colors';
import RateTable from './rateTable';
import { CallToActionHeader, StandardText, BodyTextSmall } from '../typography/typography';
import IgniteModal, { IgniteModalContent } from '../modal/igniteModal';
import RatesAndTerms from '../cms/ratesAndTerms.constants';
import ImagesFileNames from '../../images';
import SVGImage from '../svgImage';
import { useEffectOnce } from '../../utilities/reactHooks';
import { formatDisclosureWithDate } from '../../utilities/formatDisclosureWithDate';

export type ApyType = 'apyCds' | 'apyHysMoneyMarket' | 'apyNonIraCds';

type Props = {
  isAffinityCustomer: boolean;
  products: Product[];
  affinityVerbiage: string;
  terms: Term[];
  open: boolean;
  handleClose: any;
  classes: any;
  affinityCompanyCode: string;
  apyType: ApyType;
};

const styles = (theme): StyleRules => ({
  modal: {
    margin: '5vh 0',
    [theme.breakpoints.down('xs')]: {
      margin: 'unset',
    },
  },
  subHeaderContainer: {
    marginTop: pxToRem(10),
  },
  affinityContainer: {
    marginTop: pxToRem(30),
  },
  modalContent: {
    padding: `${pxToRem(48)} ${pxToRem(30)}`,
    [theme.breakpoints.up('sm')]: {
      padding: pxToRem(48),
    },
  },
  productTitleContainer: {
    marginTop: pxToRem(40),
  },
  rateTitle: {
    marginTop: pxToRem(30),
  },
  rateTable: {
    marginTop: pxToRem(17.5),
    maxWidth: pxToRem(450),
  },
  iconWrapper: {
    display: 'inline-block',
    marginRight: pxToRem(7),
  },
  affinityCircleIcon: {
    position: 'relative',
    width: pxToRem(11),
    height: pxToRem(11),
  },
});

export const getProductHighlightColor = (productCode: ProductCode): string => {
  switch (productCode) {
    case 'MMA':
      return colors.optimumBlue;
    case 'HYS':
      return colors.green;
    default:
      return colors.steelGrey;
  }
};

const productOrdinals = (ordinal: ProductCode, hasPromo: boolean) => {
  // Promo products should always be at the top, regardless of the displayCode
  if (hasPromo) {
    return -3;
  }
  switch (ordinal) {
    case 'MMA':
      return -2;
    case 'HYS':
      return -1;
    default:
      return 0;
  }
};

export const sortProductsByCode = (
  products: Product[]
): Product[] => // Shallow clone array to prevent mutating Redux state
  [...products].sort(
    (a, b) =>
      productOrdinals(a.displayCode, !!a.promotion) - productOrdinals(b.displayCode, !!b.promotion)
  );

export function RatesAndTermsDialog(props: Props) {
  const {
    apyType,
    open,
    handleClose,
    terms,
    products,
    isAffinityCustomer,
    affinityVerbiage,
    classes,
    affinityCompanyCode,
  } = props;

  const [disclosures, setDisclosures] = useState(null);

  useEffectOnce(() => {
    const fetchData = async () => {
      const data = await fetchDisclosures();
      setDisclosures(data);
    };
    fetchData();
  });

  const apyText = disclosures ? disclosures[apyType] : '';

  const formattedApyText = formatDisclosureWithDate(apyText);

  return (
    <IgniteModal
      isWide
      showCloseX
      visible={open}
      onClose={handleClose}
      onBackdropClick={handleClose}
      classes={{
        modalContentWrapper: classes.modalContent,
      }}
      className={classes.modal}
      onEscapeKeyDown={handleClose}
      title="Rates and Terms"
      titleComponent="LargestHeader"
    >
      <IgniteModalContent>
        <div className={(classes.subHeaderContainer, 'leading-[16px]')}>
          <BodyTextSmall data-test="detailed-apy-disclosure-text">
            {formattedApyText || 'Loading...'}
          </BodyTextSmall>
        </div>
        {isAffinityCustomer && (
          <div className={classes.affinityContainer} data-test="affinity-modal-container">
            <span className={classes.iconWrapper} data-test="affinity-modal-icon">
              <SVGImage
                imageName={ImagesFileNames.affinityAlertCircleSvg}
                className={classes.affinityCircleIcon}
                ariaHidden="true"
              />
            </span>
            <StandardText data-test="affinity-modal-text">
              {affinityVerbiage || RatesAndTerms.AFFINITY_RATES_HEADER}
            </StandardText>
          </div>
        )}

        {sortProductsByCode(products).map((product) => {
          const { terms: productTerms } = product;
          const productColor = getProductHighlightColor(product.displayCode);
          const tables = productTerms.reduce<Array<any>>((tableRows, termId) => {
            // NOTE: Casting until API normalizes types on backend
            const term = terms.find((t) => t.id === `${termId}`);
            if (term && term.rateMatrices && term.rateMatrices.length) {
              const key = productTerms.length > 1 ? term.id : `${product.displayCode}-term`;
              const title = productTerms.length > 1 && term.description;

              return [
                ...tableRows,
                <div key={termId}>
                  {title && (
                    <div className={classes.rateTitle}>
                      <CallToActionHeader
                        data-test={`${kebabCase(title)}-modal-title`}
                        component="h3"
                      >
                        {title}
                      </CallToActionHeader>
                    </div>
                  )}
                  <div className={classes.rateTable}>
                    <RateTable
                      key={key}
                      term={term}
                      highlightColor={productColor}
                      apyAsterisks={0}
                      headerTitle={product.displayCode === 'CD' ? 'Deposit Amount' : undefined}
                    />
                  </div>
                </div>,
              ];
            }

            return tableRows;
          }, []);

          if (!tables.length) return null;

          return (
            <React.Fragment key={product.displayCode}>
              <div className={classes.productTitleContainer}>
                <CallToActionHeader
                  textTransform="upperCase"
                  data-test={`${kebabCase(product.displayName)}-modal-title`}
                  component="h2"
                >
                  {product.displayName}
                </CallToActionHeader>
              </div>
              {tables}
            </React.Fragment>
          );
        })}
      </IgniteModalContent>
    </IgniteModal>
  );
}

export default withStyles(styles)(RatesAndTermsDialog);
