import type { TransmitState } from '../../utilities/types';
import { CONTACT_US_CODE_DEFAULT } from './applications.constants';

export const UPDATE_TRANSMIT_STATUS = 'UPDATE_TRANSMIT_STATUS';
export const ACTION_SET_CONTACT_US_CODE = 'ACTION_SET_CONTACT_US_CODE';

function getInitialState(): TransmitState {
  return {
    applicantId: [],
    applicationId: '',
    decision: '',
    iovationStatus: '',
    mfaStatus: '',
    status: {
      responseCode: '',
      responseDesc: '',
      transId: '',
    },
    token: undefined,
    contactUsCode: CONTACT_US_CODE_DEFAULT,
  };
}

const initialState = getInitialState();

export type ApplicationsTransmitAction =
  | {
      type: typeof UPDATE_TRANSMIT_STATUS;
      payload: Record<string, string>;
    }
  | {
      type: typeof ACTION_SET_CONTACT_US_CODE;
      payload: string;
    };

export default (
  state: TransmitState | null | undefined = initialState,
  action: ApplicationsTransmitAction = undefined
): TransmitState => {
  switch (action.type) {
    case UPDATE_TRANSMIT_STATUS:
      return {
        ...state,
        ...action.payload,
      };
    case ACTION_SET_CONTACT_US_CODE: {
      return { ...state, contactUsCode: action.payload };
    }
    default:
      return state;
  }
};
