// This utility method extracts the applicant info from the

import { TransmitApplicantsInfo } from './types';

// Transmit Response based on the BoD and SSN Number
const getTransmitApplicantId = (
  dateOfBirth: string,
  socialSecurityNumber: string,
  applicantsArray: TransmitApplicantsInfo[]
): string | null => {
  const applicantItem = applicantsArray.filter(
    (item) => item.dob === dateOfBirth && item.las4ssn === socialSecurityNumber.slice(-4)
  );

  if (applicantItem[0].id) return applicantItem[0].id;
  return null;
};

export default getTransmitApplicantId;
