import classnames from 'classnames';
import type { MouseEventHandler, ReactNode } from 'react';
import React, { useEffect, useRef } from 'react';
import { Booleanish } from '../../typings/custom';
import type { StyleProps } from '../../utilities/types';
import IconLinkButton from '../buttons/iconLinkButton';
import type { FlashMessageVariantType } from './flashMessage.constants';
import { FlashMessageVariant } from './flashMessage.constants';
import useStyles from './flashMessage.styles';
import ImagesFileNames from '../../images';
import SVGImage from '../svgImage';

type FlashMessageProps = {
  children?: ReactNode | string;
  className?: string;
  textToLink?: boolean;
  textToLinkText?: string;
  linkClick?: MouseEventHandler<HTMLButtonElement>;
  variant?: FlashMessageVariantType;
  resetScroll?: boolean;
  ['aria-live']?: React.AriaAttributes['aria-live'];
  ['aria-atomic']?: Booleanish;
  focusMessage?: boolean;
};

const FlashMessageClass = {
  [FlashMessageVariant.ACTION]: 'action',
  [FlashMessageVariant.DASHBOARD]: 'dashboard',
  [FlashMessageVariant.DASHBOARDBRAND]: 'dashboardBrand',
  [FlashMessageVariant.DASHBOARD_ALERT]: 'dashboardAlert',
  [FlashMessageVariant.DASHBOARD_ACTION]: 'dashboardAction',
  [FlashMessageVariant.DASHBOARD_ACTION_BRAND]: 'dashboardActionBrand',
  [FlashMessageVariant.DEFAULT]: 'default',
  [FlashMessageVariant.ERROR]: 'error',
  [FlashMessageVariant.INFO]: 'info',
  [FlashMessageVariant.SUCCESS]: 'success',
  [FlashMessageVariant.WARNING]: 'warning',
  [FlashMessageVariant.WARNINGCACHED]: 'warningCached',
  [FlashMessageVariant.WARNINGACCOUNT]: 'warningAccountDashboard',
};

const IgniteFlashMessage = (props: FlashMessageProps & StyleProps) => {
  const styles = useStyles();
  const renderTextWithLink = (childrenArg, textToLinkTextArg) => {
    const [beforeText, afterText] = childrenArg.split(textToLinkTextArg);
    const { linkClick } = props;
    return (
      <>
        {beforeText}
        <IconLinkButton inline onClick={linkClick}>
          {textToLinkTextArg}
        </IconLinkButton>
        {afterText}
      </>
    );
  };

  const {
    children,
    className,
    textToLinkText = '',
    textToLink,
    variant = FlashMessageVariant.DEFAULT,
    'aria-live': ariaLive = props.variant === FlashMessageVariant.ERROR ? 'assertive' : 'polite',
    'aria-atomic': ariaAtomic = 'true',
    resetScroll,
    focusMessage,
  } = props;

  const renderWarningIcon = () => {
    const iconClass =
      variant === FlashMessageVariant.WARNINGACCOUNT ? styles.iconAccountAlert : styles.icon;
    return (
      <div className={iconClass}>
        <SVGImage imageName={ImagesFileNames.iconWarningSvg} />
      </div>
    );
  };

  const flashMessageRef = useRef(null);
  const fullWidthAlertClass =
    variant === FlashMessageVariant.WARNINGACCOUNT ? styles.fullWidth : '';

  useEffect(() => {
    if (resetScroll) window.scrollTo(0, 0);
    // focus to keep on flash message for ADA users
    if (children && flashMessageRef.current && focusMessage) {
      flashMessageRef.current.focus();
    }
  }, [children]);

  return (
    <div
      data-test="flashMessages"
      aria-live={ariaLive}
      role="alert"
      aria-atomic={ariaAtomic}
      className={classnames(styles.root, styles[FlashMessageClass[variant]], className)}
      ref={flashMessageRef}
    >
      {(variant === FlashMessageVariant.WARNING ||
        variant === FlashMessageVariant.WARNINGCACHED ||
        variant === FlashMessageVariant.WARNINGACCOUNT) &&
        renderWarningIcon()}
      <div className={fullWidthAlertClass}>
        <span className={styles.messageBody} data-test="flash-message-text" id="flashMessage">
          {textToLink && typeof children === 'string'
            ? renderTextWithLink(children, textToLinkText)
            : children}
        </span>
      </div>
    </div>
  );
};

export default IgniteFlashMessage;
